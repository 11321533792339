.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.CreateBook {
  background-color: #2c3e50;
  min-height: 100vh;
  color: white;
}

.ShowBookDetails {
  background-color: #2c3e50;
  min-height: 100vh;
  color: white;
}

.UpdateBookInfo {
  background-color: #2c3e50;
  min-height: 100vh;
  color: white;
}

.ShowBookList {
  background-color: #2c3e50;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100px;
  color: white;
}


/* BookList Styles */

.list{
      display: flex;
      flex-wrap: wrap;
}
/* .list {
  display: grid;
  margin: 20px 0 50px 0;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 2em;
} */

.card-container {
  width: 250px;
  border: 1px solid rgba(0, 0, .125);
  margin: 21px auto;
  border-radius: 5px;
  overflow: hidden;
}
.card-container >img{
    height: 283px;
      width: 248px;
}
.desc {
  height: 130px;
  padding: 10px;
}

.desc a{
      color: #fdfdfd;
      text-decoration: underline;
}

.desc h2 {
  font-size: 1em;
  font-weight: 400;
}

.desc h3,
p {
  font-weight: 300;
}

.desc h3 {
  color: white;
  font-size: 1em;
  padding: 10px 0 10px 0;
}

@media screen and (max-width: 480px) {
  .list{
        display: flex;
        flex-direction: column;
        /* padding-bottom: 30px; */
        
  }

  .table td{
      padding: 10px 2px;
  }
}